/**
 * @flow
 */

export type RawSchedulingTeam = {
  id?: number,
  name: string,
  display_str: string,
  visible_in_regional_dashboard: boolean,
  sorting_index: ?number,
};

export type SchedulingTeam = {
  id?: number,
  name: string,
  displayStr: string,
  visibleInRegionalDashboard: boolean,
  sortingIndex: ?number,
};

export const parseRawSchedulingTeam = ({
  id,
  name,
  display_str: displayStr,
  visible_in_regional_dashboard: visibleInRegionalDashboard,
  sorting_index: sortingIndex,
}: RawSchedulingTeam): SchedulingTeam => ({
  id,
  name,
  displayStr,
  visibleInRegionalDashboard,
  sortingIndex,
});

export const parseRawSchedulingTeamArray = (rawSchedulingTeams: Array<RawSchedulingTeam>): Array<SchedulingTeam> =>
  rawSchedulingTeams.map(parseRawSchedulingTeam);
