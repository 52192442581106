/**
 * @flow
 */

import { parseRawRegionDepartment } from "../../../../common/webpack/shared/models/department";
import { parseRawOccupation } from "../../../../sched_common/webpack/shared/models/occupation";
import { parseOptionalObjectFromSideloadMap } from "../../../../common/webpack/shared/utils/parsingUtils";

import type { RegionDepartment } from "../../../../common/webpack/shared/models/department";
import type { Occupation } from "../../../../sched_common/webpack/shared/models/occupation";
import type { SideloadedMap } from "../../../../common/webpack/shared/utils/parsingUtils";

export type RawVacationGroupEligibility = {
  id: number,
  created_timestamp: string,
  department: number,
  vacation_group: number,
  created_by_user: number,
  occupation: number,
};

export type VacationGroupEligibility = {
  id: number,
  createdTimestamp: string,
  departmentID: number,
  department: ?RegionDepartment,
  vacationGroupID: number,
  createdByUserID: number,
  occupationID: number,
  occupation: ?Occupation,
};

export const parseRawVacationGroupEligibility = (
  rawVacationGroupEligibility: RawVacationGroupEligibility,
  sideloadedMap: SideloadedMap
): VacationGroupEligibility => {
  return {
    id: rawVacationGroupEligibility.id,
    createdTimestamp: rawVacationGroupEligibility.created_timestamp,
    vacationGroupID: rawVacationGroupEligibility.vacation_group,
    createdByUserID: rawVacationGroupEligibility.created_by_user,
    departmentID: rawVacationGroupEligibility.department,
    department: parseOptionalObjectFromSideloadMap(
      sideloadedMap,
      "region_departments",
      rawVacationGroupEligibility.department,
      parseRawRegionDepartment
    ),
    occupationID: rawVacationGroupEligibility.occupation,
    occupation: parseOptionalObjectFromSideloadMap(
      sideloadedMap,
      "occupations",
      rawVacationGroupEligibility.occupation,
      parseRawOccupation
    ),
  };
};

export const parseRawVacationGroupEligibilityArray = (
  rawVacationGroupEligibilityArray: Array<RawVacationGroupEligibility>,
  sideloadedMap: SideloadedMap
): Array<VacationGroupEligibility> =>
  rawVacationGroupEligibilityArray.map((rawVacationGroupEligibility) =>
    parseRawVacationGroupEligibility(rawVacationGroupEligibility, sideloadedMap)
  );
