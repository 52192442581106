/**
 * @flow
 */

import { apiBase } from "../const/apiConstants";
import { fetchWithAuthAndParseResponse, ResponseError } from "../fetchWithAuth";
import { parseRawOrgNodeArray, parseRawSimpleOrgNodeArray } from "../models/orgNode";
import type { OrgNode, RawOrgNode, RawSimpleOrgNode, SimpleOrgNode } from "../models/orgNode";

type SuggestOrgNodeAPIResponseData = Array<RawSimpleOrgNode>;

export const parseSuggestOrgNodeAPIResponseData = (data: SuggestOrgNodeAPIResponseData): Array<SimpleOrgNode> => {
  return parseRawSimpleOrgNodeArray(data);
};

export const requestSuggestOrgNode = (searchString: string): Promise<Array<SimpleOrgNode> | ResponseError> => {
  const searchParams = new URLSearchParams({
    search_term: searchString,
    search_field: "reference_path",
    page_size: "8",
  });

  return fetchWithAuthAndParseResponse(
    `${window.location.origin}${apiBase}common/business-structure-reference/suggest/?${searchParams.toString()}`,
    parseSuggestOrgNodeAPIResponseData
  );
};

type PaginationMeta = {
  previous_link: ?string,
  next_link: ?string,
  number: number,
  paginator: {
    num_pages: number,
  },
};

export type OrgNodeForUserAPIResponseData = {
  results: Array<RawOrgNode>,
  meta: PaginationMeta,
};

type OrgNodeForUserParsedResponseData = {
  results: Array<OrgNode>,
  meta: PaginationMeta,
};

export type OrgNodeForUserSearchAPIResponseData = {
  related_nodes: Array<RawOrgNode>,
  results: Array<RawOrgNode>,
  meta: PaginationMeta,
};

type OrgNodeForUserSearchParsedResponseData = {
  relatedNodes: Array<OrgNode>,
  results: Array<OrgNode>,
  meta: PaginationMeta,
};

export const parseOrgNodeSearchAPIResponseData = (
  data: OrgNodeForUserSearchAPIResponseData
): OrgNodeForUserSearchParsedResponseData => {
  return {
    results: parseRawOrgNodeArray(data.results),
    relatedNodes: parseRawOrgNodeArray(data.related_nodes),
    meta: data.meta,
  };
};

export const parseOrgNodeForUserAPIResponseData = (
  data: OrgNodeForUserAPIResponseData
): OrgNodeForUserParsedResponseData => {
  return { results: parseRawOrgNodeArray(data.results), meta: data.meta };
};

/** A method for retrieving a subset of accessible org nodes on behalf of a user.
 * @param childrenOfReferenceID the reference ID to retrieve direct children of. Pass in 'initial' to retrieve the
 *  top node of each subtree a user has access to.
 * @param targetEmployeeID the user to retrieve nodes on behalf of. The requesting user must have permission to
 *  manage this user, or they'll receive a permission denied response.
 * @returns {Promise<ResponseError|Array<OrgNode>>}
 */
export const requestOrgNodesForUser = (
  childrenOfReferenceID: string,
  targetEmployeeID?: ?string
): Promise<OrgNodeForUserParsedResponseData | ResponseError> => {
  const searchParams = new URLSearchParams();
  if (childrenOfReferenceID !== "initial") {
    searchParams.append("children_of_reference_id", childrenOfReferenceID);
  }
  if (targetEmployeeID != null) {
    searchParams.append("target_employee_id", targetEmployeeID);
  }
  return fetchWithAuthAndParseResponse(
    `${window.location.origin}${apiBase}common/business-structure-reference/?${searchParams.toString()}`,
    parseOrgNodeForUserAPIResponseData
  );
};

export const requestSearchOrgNodesForUser = (
  searchTerm: string,
  searchField: string,
  targetEmployeeID?: ?string
): Promise<OrgNodeForUserSearchParsedResponseData | ResponseError> => {
  const searchParams = new URLSearchParams({
    search_term: searchTerm,
    search_field: searchField,
    page_size: "25", // since we need to get (potentially) lots of ancestry data, keep this value fairly small
  });
  if (targetEmployeeID != null) {
    searchParams.append("target_employee_id", targetEmployeeID);
  }
  return fetchWithAuthAndParseResponse(
    `${window.location.origin}${apiBase}common/business-structure-reference/search/?${searchParams.toString()}`,
    parseOrgNodeSearchAPIResponseData
  );
};
