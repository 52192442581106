/**
 * @flow
 */

import { parseRawVacationGroup } from "./vacationGroup";
import { parseRawVacationRoundDateRangeQuota } from "./vacationRoundDateRangeQuota";
import { parseRawVacationRoundParticipantArray } from "./vacationRoundParticipant";
import {
  parseOptionalArrayFromSideloadMap,
  parseOptionalObjectFromSideloadMap,
} from "../../../../common/webpack/shared/utils/parsingUtils";

import type { VacationRoundDateRangeQuota } from "./vacationRoundDateRangeQuota";
import type { RawVacationRoundParticipant, VacationRoundParticipant } from "./vacationRoundParticipant";
import type { VacationGroup } from "./vacationGroup";
import type { SideloadedMap } from "../../../../common/webpack/shared/utils/parsingUtils";

export const VacationRoundStates = {
  Created: ("created": "created"),
  ParticipantsSet: ("participants_set": "participants_set"),
  ManagerProcessed: ("manager_processed": "manager_processed"),
};

export type VacationRoundStatesType = $Values<typeof VacationRoundStates>;

export type RawVacationRound = {
  id: number,
  participants: ?Array<RawVacationRoundParticipant>,
  quotas: ?Array<number>,
  vacation_group: number,
  name: string,
  created_timestamp: string,
  open_timestamp: string,
  close_timestamp: string,
  created_by_user: number,
  processed_by_user: ?number,
  fsm_state: [VacationRoundStatesType, string],
  is_accepting_employee_submissions: boolean,
  can_be_processed_by_approvers: boolean,
  can_be_submitted_by_approvers: boolean,
  is_active?: boolean,
  vacation_round_participant_count: ?number,
  requested_vacation_round_participant_count: ?number,
  ready_vacation_round_participant_count: ?number,
  most_senior_unsubmitted_participant_name: ?string,
  next_participant_and_list_rank_to_process?: ?[number, ?number],
  uses_multiple_request_lists: boolean,
};

export type VacationRound = {
  id: number,
  participants: ?Array<VacationRoundParticipant>,
  quotas: ?Array<VacationRoundDateRangeQuota>,
  quotaIDs: Array<number>,
  vacationGroupID: number,
  vacationGroup: ?VacationGroup,
  name: string,
  createdTimestamp: string,
  openTimestamp: string,
  closeTimestamp: string,
  createdByUserID: number,
  processedByUserID: ?number,
  state: VacationRoundStatesType,
  displayState: string,
  isAcceptingEmployeeSubmissions: boolean,
  canBeProcessedByApprovers: boolean,
  canBeSubmittedByApprovers: boolean,
  isActive: ?boolean, // This is a dynamic field and might not always exist.
  vacationRoundParticipantCount: ?number,
  requestedVacationRoundParticipantCount: ?number,
  readyVacationRoundParticipantCount: ?number,
  mostSeniorUnsubmittedParticipantName: ?string,
  nextParticipantIdAndListRankToProcess?: ?[number, ?number],
  usesMultipleRequestLists: boolean,
};

/**
 * @param rawVacationRound
 * @param sideloadedMap
 * @param includeGroup - whether to parse the vacationGroup data or not. Since vacationGroup contains vacationRounds,
 *  if vacationRounds also contain vacationGroup we can get an infinite parsing loop.
 * @param includeParticipants - whether to parse the vacationRoundParticipant data or not.
 * @returns VacationRound
 */
export const parseRawVacationRound = (
  rawVacationRound: RawVacationRound,
  sideloadedMap: SideloadedMap,
  includeGroup?: boolean,
  includeParticipants?: boolean
): VacationRound => {
  return {
    id: rawVacationRound.id,
    // Participants aren't loaded via the sideloadedMap because they're embedded in the serializer. We should never
    // embed anything going forward, to make this more straight-forward and avoid exceptional cases like this.
    participants:
      includeParticipants && rawVacationRound.participants != null
        ? parseRawVacationRoundParticipantArray(rawVacationRound.participants, sideloadedMap)
        : null,
    quotas:
      rawVacationRound.quotas != null
        ? parseOptionalArrayFromSideloadMap(
            sideloadedMap,
            "quotas",
            rawVacationRound.quotas,
            parseRawVacationRoundDateRangeQuota
          )
        : null,
    quotaIDs: rawVacationRound.quotas || [],
    vacationGroupID: rawVacationRound.vacation_group,
    vacationGroup: includeGroup
      ? parseOptionalObjectFromSideloadMap(
          sideloadedMap,
          "vacation_groups",
          rawVacationRound.vacation_group,
          parseRawVacationGroup
        )
      : null,
    name: rawVacationRound.name,
    createdTimestamp: rawVacationRound.created_timestamp,
    openTimestamp: rawVacationRound.open_timestamp,
    closeTimestamp: rawVacationRound.close_timestamp,
    createdByUserID: rawVacationRound.created_by_user,
    processedByUserID: rawVacationRound.processed_by_user,
    state: rawVacationRound.fsm_state[0],
    displayState: rawVacationRound.fsm_state[1],
    isAcceptingEmployeeSubmissions: rawVacationRound.is_accepting_employee_submissions,
    canBeProcessedByApprovers: rawVacationRound.can_be_processed_by_approvers,
    canBeSubmittedByApprovers: rawVacationRound.can_be_submitted_by_approvers,
    isActive: rawVacationRound.is_active != null ? rawVacationRound.is_active : null,
    vacationRoundParticipantCount: rawVacationRound.vacation_round_participant_count,
    requestedVacationRoundParticipantCount: rawVacationRound.requested_vacation_round_participant_count,
    readyVacationRoundParticipantCount: rawVacationRound.ready_vacation_round_participant_count,
    mostSeniorUnsubmittedParticipantName: rawVacationRound.most_senior_unsubmitted_participant_name,
    nextParticipantIdAndListRankToProcess: rawVacationRound.next_participant_and_list_rank_to_process,
    usesMultipleRequestLists: rawVacationRound.uses_multiple_request_lists,
  };
};

export const parseRawVacationRoundArray = (
  rawVacationRoundArray: Array<RawVacationRound>,
  sideloadedMap: SideloadedMap
): Array<VacationRound> =>
  rawVacationRoundArray.map((rawVacationRound) => parseRawVacationRound(rawVacationRound, sideloadedMap));
