/**
 * @flow
 */

import { parseRawUnion } from "./union";
import { parseRawHSMSUser } from "../../../../hsms_auth/webpack/shared/models/hsmsUser";
import { parseOptionalObjectFromSideloadMap } from "../utils/parsingUtils";
import type { Union } from "./union";
import type { HSMSUser, RawHSMSUser } from "../../../../hsms_auth/webpack/shared/models/hsmsUser";
import type { SideloadedMap } from "../utils/parsingUtils";

export type RawRegionEmployee = {
  id: number,
  external_employee_number: string,
  position_number: number,
  hsms_user: RawHSMSUser,
  union: number,
  home_occupation: number,
  work_phone: string,
  scheduling_phone_1?: ?string,
  scheduling_phone_2?: ?string,
  contact_email: ?string,
  work_email: ?string,
  vacation_bank_required_hours: number,
  seniority_display: string,
  number_of_recently_granted_shifts?: number,
  display_str: string,
};

export type RegionEmployee = {
  id: number,
  externalEmployeeNumber: string,
  positionNumber: number,
  hsmsUser: HSMSUser,
  union: ?Union,
  homeOccupationID: number,
  workPhoneNumber: string,
  schedulingPhoneNumberOne?: ?string,
  schedulingPhoneNumberTwo?: ?string,
  contactEmail: ?string,
  workEmail: ?string,
  vacationBankRequiredHours: number,
  seniorityDisplay: string,
  numberOfRecentlyGrantedShifts?: number,
  displayStr: string,
};

export const parseRawRegionEmployee = (
  rawRegionEmployee: RawRegionEmployee,
  sideloadedMap: SideloadedMap
): RegionEmployee => {
  const {
    id,
    hsms_user: hsmsUser,
    number_of_recently_granted_shifts: numberOfRecentlyGrantedShifts,
  } = rawRegionEmployee;
  return {
    id,
    externalEmployeeNumber: rawRegionEmployee.external_employee_number,
    positionNumber: rawRegionEmployee.position_number,
    hsmsUser: parseRawHSMSUser(hsmsUser),
    union: parseOptionalObjectFromSideloadMap(sideloadedMap, "unions", rawRegionEmployee.union, parseRawUnion),
    homeOccupationID: rawRegionEmployee.home_occupation,
    workPhoneNumber: rawRegionEmployee.work_phone,
    schedulingPhoneNumberOne: rawRegionEmployee.scheduling_phone_1,
    schedulingPhoneNumberTwo: rawRegionEmployee.scheduling_phone_2,
    contactEmail: rawRegionEmployee.contact_email,
    workEmail: rawRegionEmployee.work_email,
    vacationBankRequiredHours: rawRegionEmployee.vacation_bank_required_hours,
    seniorityDisplay: rawRegionEmployee.seniority_display,
    displayStr: rawRegionEmployee.display_str,
    ...(numberOfRecentlyGrantedShifts != null && { numberOfRecentlyGrantedShifts }),
  };
};

export const parseRawRegionEmployeeArray = (
  rawRegionEmployees: Array<RawRegionEmployee>,
  sideloadedMap: SideloadedMap
): Array<RegionEmployee> => rawRegionEmployees.map((rawEmployee) => parseRawRegionEmployee(rawEmployee, sideloadedMap));

export type RawSimpleRegionEmployee = {
  id: number,
  name: string,
  external_employee_number: string,
  active_status: string,
};

export type SimpleRegionEmployee = {|
  id: number,
  name: string,
  externalEmployeeNumber: string,
  activeStatus: string,
|};

export const parseRawSimpleRegionEmployee = (rawRegionEmployee: RawSimpleRegionEmployee): SimpleRegionEmployee => {
  return {
    id: rawRegionEmployee.id,
    name: rawRegionEmployee.name,
    externalEmployeeNumber: rawRegionEmployee.external_employee_number,
    activeStatus: rawRegionEmployee.active_status,
  };
};

export const parseRawSimpleRegionEmployeeArray = (
  rawRegionEmployees: Array<RawSimpleRegionEmployee>
): Array<SimpleRegionEmployee> => rawRegionEmployees.map(parseRawSimpleRegionEmployee);
