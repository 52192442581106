/**
 * @flow
 */

import React, { useState, type Node } from "react";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useSearch } from "../hooks/useSearch";
import { ResponseError } from "../fetchWithAuth";
import { FontAwesomeIcon } from "./FontAwesomeIcon";

// Whether, on select, we should:
// 1) clear the input
// 2) fill the input field with the selection
// 3) do nothing.
// NOTE: Regardless of this setting, 'onSelectFn' will still be called.
type SelectBehaviour = "none" | "clear" | "fill";
type StringOrNumber = string | number;

export type Props<ResultType> = {|
  // An input name is only really required if the field is in a form we plan to submit.
  fieldInputName?: string,
  ariaLabelledBy: string,
  cacheKey?: string,
  style?: any,
  required?: boolean,
  placeholderText?: string,
  minCharacters?: number,
  maxResults?: number,
  searchRequestFn: (searchTerm: string) => Promise<Array<ResultType> | ResponseError>,
  resultKeyFn: (result: ResultType) => StringOrNumber,
  resultDisplayFn: (result: ResultType) => string,
  // If some results should be excluded, pass the result keys via this prop.
  excludedResultKeys?: Array<string | number>,
  onSelectFn?: (selected: ResultType) => void,
  onSelectBehaviour?: SelectBehaviour,
|};

export const AutoSuggestInput = <ResultType>({
  fieldInputName,
  ariaLabelledBy,
  cacheKey,
  placeholderText,
  required = false,
  minCharacters = 3,
  maxResults = 8, // ~8 is generally considered the max a human wants to process at once.
  searchRequestFn,
  resultKeyFn,
  resultDisplayFn,
  excludedResultKeys,
  onSelectFn,
  onSelectBehaviour = "none",
}: Props<ResultType>): Node => {
  const [selected, setSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const searchTermIsBelowMinCharacters = searchTerm.length < minCharacters;
  const searchTermOfAtLeastMinCharacters = searchTermIsBelowMinCharacters ? "" : searchTerm;
  const [searchResults, , isSearchLoading] = useSearch(searchTermOfAtLeastMinCharacters, searchRequestFn, cacheKey);
  const searchResultsToDisplay = searchResults.slice(0, maxResults);

  const resultDisplayToResult = {};
  searchResultsToDisplay.forEach((result) => {
    resultDisplayToResult[resultDisplayFn(result)] = result;
  });

  const selectableResults =
    excludedResultKeys != null && excludedResultKeys.length > 0
      ? searchResultsToDisplay.filter((result) => !excludedResultKeys.includes(resultKeyFn(result)))
      : searchResultsToDisplay;

  const signalAutoSuggestInputChanged = (value) => {
    // Signals that a choice has been entered. Used by listening model choice fields that depend on the auto suggest.
    if (value in resultDisplayToResult && fieldInputName) {
      const selectedResult = resultDisplayToResult[value];
      const resultKey = resultKeyFn(selectedResult);
      const event = new CustomEvent("signal-auto-suggest-input-changed", { detail: resultKey });
      const elements = document.getElementsByName(fieldInputName);
      if (elements.length > 0) {
        elements[0].dispatchEvent(event);
      }
    }
  };

  const handleSearchTermChange = (event) => {
    if (selected) {
      setSelected(false);
    }
    setSearchTerm(event.target.value);
    signalAutoSuggestInputChanged(event.target.value);
  };

  const handleSelect = (value) => {
    const selectedResult = resultDisplayToResult[value];
    if (onSelectFn) {
      onSelectFn(selectedResult);
    }
    if (onSelectBehaviour === "clear") {
      setSearchTerm("");
    } else if (onSelectBehaviour === "fill") {
      setSearchTerm(value);
    }
    setSelected(true);
    signalAutoSuggestInputChanged(value);
  };

  return (
    <Combobox style={{ flexGrow: 1 }} aria-labelledby={ariaLabelledBy} onSelect={handleSelect}>
      <p className="control has-icons-right has-icons-left">
        <ComboboxInput
          className="input"
          // Turn browser auto-complete off so it doesn't render a conflicting auto-complete UI.
          autoComplete="off"
          required={required}
          name={fieldInputName}
          onChange={handleSearchTermChange}
          value={searchTerm}
          placeholder={placeholderText}
          data-testid={cacheKey}
        />
        <FontAwesomeIcon icon={{ style: "solid", name: "search" }} size="small" additionalContainerClasses="is-left" />
        {isSearchLoading && (
          <FontAwesomeIcon
            icon={{ style: "solid", name: "spinner" }}
            size="small"
            additionalClasses="fa-pulse"
            additionalContainerClasses="is-right"
          />
        )}
      </p>
      {/* If the user clicks one of the auto-suggest options, hide the popover. It will reappear if they make any
      changes to the selection by typing, as you'd expect. */}
      {!selected && (
        <ComboboxPopover>
          {selectableResults.length > 0 ? (
            <ComboboxList>
              {selectableResults.map((result: ResultType) => {
                const str = resultDisplayFn(result);
                return <ComboboxOption className="is-size-6 has-text-grey-darker" key={str} value={str} />;
              })}
            </ComboboxList>
          ) : (
            <span className="is-size-6 has-text-grey-darker" style={{ display: "block", margin: 8 }}>
              {
                // prettier-ignore
                isSearchLoading ? window.gettext("Loading...") :
                searchTermIsBelowMinCharacters ? window.gettext("Type more to see suggestions") :
                window.gettext("No results found")
              }
            </span>
          )}
        </ComboboxPopover>
      )}
    </Combobox>
  );
};
