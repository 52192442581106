/**
 * @flow
 */

import { type VacationGroup, parseRawVacationGroup } from "./vacationGroup";
import { parseOptionalArrayFromSideloadMap } from "../../../../common/webpack/shared/utils/parsingUtils";
import type { SideloadedMap } from "../../../../common/webpack/shared/utils/parsingUtils";

export type RawVacationProcess = {
  id: number,
  name: string,
  created_timestamp: string,
  created_by_user: number,
  shift_selection_start_date: string,
  shift_selection_end_date: string,
  shift_selection_overflow_end_date: ?string,
  is_active: boolean,
  vacation_groups: Array<number>,
  vacation_group_creation_start_timestamp: string,
  vacation_group_creation_end_timestamp: string,
};

export type VacationProcess = {
  id: number,
  name: string,
  createdTimestamp: string,
  createdByUserID: number,
  shiftSelectionStartDate: string,
  shiftSelectionEndDate: string,
  shiftSelectionOverflowEndDate: ?string,
  isActive: boolean,
  vacationGroupCreationStartTimestamp: string,
  vacationGroupCreationEndTimestamp: string,
  vacationGroupIDs: Array<number>,
  vacationGroups: ?Array<VacationGroup>,
};

export const parseRawVacationProcess = (
  rawVacationProcess: RawVacationProcess,
  sideloadedMap: SideloadedMap,
  includeGroups?: boolean
): VacationProcess => {
  const {
    id,
    name,
    created_timestamp: createdTimestamp,
    created_by_user: createdByUserID,
    shift_selection_start_date: shiftSelectionStartDate,
    shift_selection_end_date: shiftSelectionEndDate,
    shift_selection_overflow_end_date: shiftSelectionOverflowEndDate,
    is_active: isActive,
    vacation_group_creation_start_timestamp: vacationGroupCreationStartTimestamp,
    vacation_group_creation_end_timestamp: vacationGroupCreationEndTimestamp,
    vacation_groups: vacationGroupIDs,
  } = rawVacationProcess;

  return {
    id,
    name,
    createdTimestamp,
    createdByUserID,
    shiftSelectionStartDate,
    shiftSelectionEndDate,
    shiftSelectionOverflowEndDate,
    isActive,
    vacationGroupCreationStartTimestamp,
    vacationGroupCreationEndTimestamp,
    vacationGroupIDs,
    vacationGroups: includeGroups
      ? parseOptionalArrayFromSideloadMap(sideloadedMap, "vacation_groups", vacationGroupIDs, parseRawVacationGroup)
      : null,
  };
};

export function parseRawVacationProcessArray(
  rawVacationProcesses: Array<RawVacationProcess>,
  sideloadedMap: SideloadedMap
): Array<VacationProcess> {
  return rawVacationProcesses.map((rawVacationProcess) => parseRawVacationProcess(rawVacationProcess, sideloadedMap));
}
