/**
 * @flow
 */

import type { SideloadedMap } from "../../../../common/webpack/shared/utils/parsingUtils";
import { parseOptionalObjectFromSideloadMap } from "../../../../common/webpack/shared/utils/parsingUtils";
import type { VacationGroupEligibility } from "./vacationGroupEligibility";
import { parseRawVacationGroupEligibility } from "./vacationGroupEligibility";

export type RawVacationRoundParticipantEligibilityRange = {
  id: number,
  start_date: string,
  end_date: string,
  vacation_group_eligibility?: number,
};

export type VacationRoundParticipantEligibilityRange = {
  id: number,
  startDate: string,
  endDate: string,
  vacationGroupEligibility?: VacationGroupEligibility,
};

export function parseRawVacationRoundParticipantEligibilityRange(
  rawVacationRoundParticipantEligibilityRange: RawVacationRoundParticipantEligibilityRange,
  sideloadedMap: SideloadedMap
): VacationRoundParticipantEligibilityRange {
  const { id, start_date: startDate, end_date: endDate } = rawVacationRoundParticipantEligibilityRange;
  const vacationGroupEligibility = rawVacationRoundParticipantEligibilityRange.vacation_group_eligibility
    ? parseOptionalObjectFromSideloadMap(
        sideloadedMap,
        "vacation_group_eligibilities",
        rawVacationRoundParticipantEligibilityRange.vacation_group_eligibility,
        parseRawVacationGroupEligibility
      )
    : null;

  if (vacationGroupEligibility) {
    return { id, startDate, endDate, vacationGroupEligibility };
  }
  return { id, startDate, endDate };
}

export function parseRawVacationRoundParticipantEligibilityRangeArray(
  rawVacationRoundParticipantEligibilityRanges: Array<RawVacationRoundParticipantEligibilityRange>,
  sideloadedMap: SideloadedMap
): Array<VacationRoundParticipantEligibilityRange> {
  return rawVacationRoundParticipantEligibilityRanges.map((rawVacationRoundParticipantEligibilityRange) =>
    parseRawVacationRoundParticipantEligibilityRange(rawVacationRoundParticipantEligibilityRange, sideloadedMap)
  );
}
