/**
 * @flow
 */

const SenioritySortMap = {
  "-seniority_hours": (window.gettext("Hours"): string),
  seniority_date: (window.gettext("Start Date"): string),
};

export type RawUnion = {
  id: number,
  abbr: string,
  seniority_sort_field: $Keys<typeof SenioritySortMap>,
  in_scope: boolean,
};

export type Union = {
  id: number,
  abbr: string,
  senioritySortField: {
    canonicalName: $Keys<typeof SenioritySortMap>,
    displayName: $Values<typeof SenioritySortMap>,
  },
  inScope: boolean,
};

export const parseRawUnion = (rawUnion: RawUnion): Union => ({
  id: rawUnion.id,
  abbr: rawUnion.abbr,
  senioritySortField: {
    canonicalName: rawUnion.seniority_sort_field,
    displayName: SenioritySortMap[rawUnion.seniority_sort_field],
  },
  inScope: rawUnion.in_scope,
});

export const parseRawUnionArray = (rawUnions: Array<RawUnion>): Array<Union> => rawUnions.map(parseRawUnion);
