/**
 * @flow
 */

import { AUTO_SUGGEST_TYPES_MAP as COMMON_AUTO_SUGGEST_TYPES_MAP } from "../commonAutoSuggest";
import { AUTO_SUGGEST_TYPES_MAP as ANNUAL_VACATION_AUTO_SUGGEST_TYPES_MAP } from "../../../../annual_vacation/webpack/reactApps/annualVacationAutoSuggest";
import { initializeAutoSuggestFieldsForMap } from "../../shared/utils/autoSuggestUtils";

// Eventually I'd like to dynamically load these, as needed, but until we put more time into more advanced
// webpack bundle splitting we'll load them all at once, via this 'initialize' helper app.
const AUTO_SUGGEST_TYPES_MAP = {
  ...COMMON_AUTO_SUGGEST_TYPES_MAP,
  ...ANNUAL_VACATION_AUTO_SUGGEST_TYPES_MAP,
};

document.addEventListener("DOMContentLoaded", () => {
  initializeAutoSuggestFieldsForMap(AUTO_SUGGEST_TYPES_MAP);
});
