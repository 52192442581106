/**
 * @flow
 */

export type RawHSMSUser = {
  id: number,
  username: string,
  first_name: string,
  last_name: string,
  display_str: ?string,
};

export type HSMSUser = {
  id: number,
  username: string,
  name: string,
  displayStr: string,
};

export const parseRawHSMSUser = (rawHSMSUser: RawHSMSUser): HSMSUser => {
  const { id, username, first_name: firstName, last_name: lastName } = rawHSMSUser;
  const name = `${firstName} ${lastName}`;
  const displayStr = rawHSMSUser.display_str ? rawHSMSUser.display_str : name;
  return { id, username, name, displayStr };
};

export const parseRawHSMSUsers = (rawHSMSUsers: Array<RawHSMSUser>): Array<HSMSUser> =>
  rawHSMSUsers.map((rawHSMSUser) => parseRawHSMSUser(rawHSMSUser));
