/**
 * @flow
 */

import "whatwg-fetch";
import includes from "lodash/includes";
import range from "lodash/range";
import { logBreadcrumbToSentry, logErrorBreadcrumbToSentryAndRethrow } from "./utils/sentryUtils";
import { InternalOnlyError } from "./utils/errors";

// Our server kills requests after 30 seconds of 'live' time.
// There could also be queue time, so we pad that by 15 seconds.
const TIMEOUT_MS = 45000;

/* eslint-enable i18next/no-literal-string */

export type ResponseNoDataSuccess = {|
  statusCode: number,
  responseType: "SUCCESS_NO_DATA",
|};

export type ResponseDataSuccess<SuccessType> = {|
  statusCode: number,
  responseType: "SUCCESS",
  data: SuccessType,
|};

type ResponseIndividualError = {|
  code: number,
  message: string,
|};

export const NonFieldErrors = ("non_field_errors": "non_field_errors");
export type FieldError = {
  id?: number,
  code?: number,
  message: string,
  field?: string,
};

export type NonFieldError = {
  id?: number,
  code?: number,
  field: typeof NonFieldErrors,
  message: {
    __all__: string,
  },
};

export type ResponseErrorType = {
  ...$Exact<ResponseIndividualError>,
  errors?: Array<NonFieldError | FieldError>,
};

export const innerErrorMessageFromError = (innerError: NonFieldError | FieldError): string => {
  if (typeof innerError.message === "object") {
    return `${innerError.message.__all__}`;
  }
  return innerError.message;
};

export const getDescriptiveErrorMessage = (error: ResponseErrorType): string => {
  // Some errors, like non_field_errors, have a very unuseful message (like "ValidationError") and the actual useful
  // error message has to be taken from the errors themselves.
  if (error.errors != null && error.errors.length > 0) {
    const innerError: FieldError | NonFieldError = error.errors[0];
    const innerErrorMessage = innerErrorMessageFromError(innerError);
    if (innerErrorMessage) {
      return `${error.message} - ${innerErrorMessage}`;
    }
  }

  return error.message;
};

export class ResponseError {
  code: number;

  message: string;

  descriptiveErrorMessage: string;

  errors: ?Array<FieldError | NonFieldError>;

  constructor(error: ResponseErrorType) {
    this.code = error.code;
    this.message = error.message;
    this.descriptiveErrorMessage = getDescriptiveErrorMessage(error);
    this.errors = error.errors;
  }
}

export type ResponseErrorPackage = {
  responseType: "ERROR",
  error: ResponseErrorType,
};

export const ResponseInternalServerError = {
  code: 500,
  message: `${window.gettext("Unknown Error")}`,
};

export type ResponseWithDataSuccessOrError<SuccessType> = ResponseDataSuccess<SuccessType> | ResponseErrorPackage;

/**
 * I've only implemented enough of the 'Response' type for our purposes, as I can't find a good/complete type
 * definition for it. More properties may need to be added as we make greater use of this file.
 */
export type Response<SuccessType> = {
  headers: any,
  ok: boolean,
  redirected: boolean,
  status: number,
  statusText: string,
  url: string,

  +text: () => Promise<string>,
  +json: () => Promise<SuccessType | ResponseError>,
};

function timeoutPromise(ms: number, promise: any) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new InternalOnlyError(`Request took longer than ${ms}ms and timed out.`));
    }, ms);
    promise.then(resolve, reject);
  });
}

/**
 * This is effectively a middleware hook into receiving a handled server error, when our server sent us back the
 * expected error format.
 *
 * If our server knows what's going on and throws an exception on purpose, we'll end up with a success response with
 * an error dictionary describing the error the server noticed. This is the error handler we'll enter in that case.
 *
 * @param error
 */
export function handleErrorJSON(error: ResponseError): void {
  console.log(`[${error.code}] Error `, error); // eslint-disable-line no-console
}

// using jQuery - from Django docs: https://docs.djangoproject.com/en/2.0/ref/csrf/#ajax
export function getCookie(name: string): ?string {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    cookies.every((rawCookie) => {
      const cookie = rawCookie.trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        return false; // Returning false breaks the loop.
      }
      return true;
    });
  }
  return cookieValue;
}

/* eslint-disable i18next/no-literal-string */
const malformedResponseError = {
  code: 500,
  message: "Malformed Response",
};
/* eslint-disable i18next/no-literal-string */

const HTTP_202_ACCEPTED = 202;
const HTTP_204_NO_CONTENT = 204;
const HTTP_401_UNAUTHORIZED = 401;

const contentTypeHeaderIncludesApplicationJson = (headers) => includes(headers.get("content-type"), "application/json");

const isValidJSONResponse = (response): boolean => {
  // We assume we got a bad response from the server (e.g. it returned HTML when we expected JSON) if the response
  // isn't 202, 204, or 401 (in which case there's no response data), or if the response doesn't include the json
  // content-type header.
  return (
    ![HTTP_202_ACCEPTED, HTTP_204_NO_CONTENT, HTTP_401_UNAUTHORIZED].includes(response.status) ||
    contentTypeHeaderIncludesApplicationJson(response.headers)
  );
};

export function parseResponseData<APIResponse, Parsed>(
  response: ResponseDataSuccess<APIResponse>,
  parseFn: (APIResponse) => Parsed
): Parsed | ResponseError {
  if (response.responseType === "SUCCESS") {
    return parseFn(response.data);
  }
  return new ResponseError(malformedResponseError);
}

export function getErrorMessageFromRejectedPromise(error: any): string {
  if (
    // The native JS error object always contains a non-nullable message string.
    error instanceof Error ||
    // Sometimes we might have an object shaped like an error, with a message, if so, we can use that.
    // $FlowExpectedError
    (Object.prototype.hasOwnProperty.call(error, "message") && error.message instanceof String)
  ) {
    // Handle Firefox and Chrome's specific fetch errors that occur when they fail to connect to the target
    // server (eg. our server is down, the DNS lookup fails, etc).
    if (
      [
        "Failed to fetch", // Chrome
        "NetworkError when attempting to fetch resource.", // Firefox
        "Network request failed", // Safari and others that don't support fetch (from polyfill).
      ].includes(error.message)
    ) {
      return window.gettext("A network error occurred. Please try again later.");
    }
    return error.message;
  }
  if (error instanceof ResponseError) {
    return error.descriptiveErrorMessage;
  }
  // Sometimes we may have rejected a promise with a string instead of an error, if so, use that string.
  if (error instanceof String) {
    return error;
  }
  // If we get an error that isn't an error, error-like object, or a string, give up and use a generic message.
  return window.gettext("An unknown error has occurred.");
}

/**
 * This makes use of the built-in browser 'fetch' method. It differs because it automatically fetches the 'csrftoken',
 * as formatted and stored by Django, and passes it along with the fetch request via HTTP Headers. Note that you can
 * override the automatically included headers, but you likely will never want to.
 *
 * For more info on fetch, see: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 *
 * @param requestURL The URL to 'fetch' from.
 * @param options The options to send with the request.
 * @returns {Promise<Response>}
 */
export default function fetchWithAuth<SuccessType>(
  requestURL: string,
  options: any = {}
): Promise<ResponseDataSuccess<SuccessType> | ResponseNoDataSuccess | ResponseErrorPackage> {
  const cookie = getCookie("csrftoken");
  // If we're testing, lets not worry about CSRF tokens, since the API call responses are mocked.
  if (cookie == null && process.env.NODE_ENV !== "test") {
    throw new InternalOnlyError("Retrieving CSRFToken failed.");
  }
  const { headers, credentials, ...rest } = options;
  return timeoutPromise(
    TIMEOUT_MS,
    new Promise((resolve, reject) => {
      fetch(requestURL, {
        credentials: "same-origin" || credentials,
        headers: {
          Accept: "application/json",
          "X-CSRFToken": cookie,
          "Content-Type": "application/json",
          ...headers,
        },
        ...rest,
      })
        .then((response) => {
          // Validate we have a JSON response. If we don't, we resolve with responseType ERROR.
          if (!isValidJSONResponse(response)) {
            // Our server might return us 400 or 500 errors that aren't properly formatted JSON, but we can still build
            // a valid error, if we have the status code and status text.
            if ((response?.status || 0) > 399 && response?.statusText != null) {
              return resolve({
                responseType: "ERROR",
                error: {
                  code: response.status,
                  message: response.statusText,
                },
              });
            }
            // If we don't have a status or status text, use an unknown internal server error.
            return resolve({
              responseType: "ERROR",
              error: ResponseInternalServerError,
            });
          }

          // If the response is a 202 or 204 .json() will throw because the response includes no data.
          if (response.status in [HTTP_202_ACCEPTED, HTTP_204_NO_CONTENT]) {
            return resolve(
              ({
                responseType: "SUCCESS_NO_DATA",
                statusCode: response.status,
              }: ResponseNoDataSuccess)
            );
          }

          // Our JSON response should include either 'error' or 'data' as a root key. 'error' takes precedence.
          // This return format is assumed because of the HSMS API convention.
          return response
            .json()
            .then((responseJSON) => {
              if (responseJSON.error != null) {
                handleErrorJSON(responseJSON.error);
                return resolve(
                  ({
                    responseType: "ERROR",
                    error: responseJSON.error,
                  }: ResponseErrorPackage)
                );
              }
              if (responseJSON.data != null) {
                return resolve(
                  ({
                    responseType: "SUCCESS",
                    data: responseJSON.data,
                    statusCode: response.status,
                  }: ResponseDataSuccess<SuccessType>)
                );
              }
              return reject(malformedResponseError);
            })
            .catch((jsonError) => {
              logBreadcrumbToSentry(jsonError);
              return reject(malformedResponseError);
            });
        })
        .catch((error) => {
          // Basically we want to catch our 'inner' exception and reject it with the outer promise's reject.
          // I thought things worked this way by default, but this is required to get appropriate behaviour.
          reject(error);
        });
    })
  );
}

export function fetchWithAuthAndParseResponse<APIResponse, Parsed>(
  requestURL: string,
  parseFn: (APIResponse) => Parsed,
  options: any = {}
): Promise<Parsed | ResponseError> {
  return fetchWithAuth(requestURL, options).then((response) => {
    if (response.responseType === "SUCCESS") {
      return parseResponseData(response, parseFn);
    }
    if (response.responseType === "ERROR") {
      return new ResponseError(response.error);
    }
    logBreadcrumbToSentry(response);
    throw new InternalOnlyError("We're in an unknown state. fetchWithAuth returned invalid data.");
  });
}

export function fetchWithNoExpectedResponseData(
  requestURL: string,
  options: any = {}
): Promise<ResponseNoDataSuccess | ResponseError> {
  return fetchWithAuth(requestURL, options).then((response) => {
    if (response.responseType === "ERROR") {
      return new ResponseError(response.error);
    }
    return {
      responseType: "SUCCESS_NO_DATA",
      statusCode: response.statusCode,
    };
  });
}

function requestURLWithPageParam(rawURL, page) {
  const url = new URL(rawURL);
  url.searchParams.set("page", page.toString());
  return url.toString();
}

/**
 * This method is a helper for querying from an API where paginated results need to be handled. This function is meant
 * to mask the pagination and return all the results at once, for parsing. So, this can't be used to query 1 page,
 * display it, query another page, then display that, etc. This fetches all the data before returning a successful
 * promise response.
 *
 * NOTE: This method expects the API to return pagination data in the dynamic rest format we use. Eg.
 *  "meta":{
 *    "total_results":0,
 *    "per_page":1000,
 *    "total_pages":1,
 *    "page":1
 *  }
 * That said, currently only { "meta": { "total_pages": X } } is required, the rest of the values are unused.
 *
 * @param requestURL The request URL, this should not include page data, if it does it will be overwritten.
 * @param options Any additional needed options for the 'fetch'. Extra HTTP headers, for example.
 * @returns {Promise} If successful, a promise with an array of valid responses. Otherwise a promise rejection.
 */
export async function fetchAllPagesAndParseResponses<APIResponse>(
  requestURL: string,
  options: any = {}
): Promise<Array<ResponseDataSuccess<APIResponse>>> {
  let totalPages = null;
  let allResponses = [];
  const pageOneRequestURL = requestURLWithPageParam(requestURL, 1);

  const response = await timeoutPromise(TIMEOUT_MS, fetchWithAuth(pageOneRequestURL, options)).catch(
    logErrorBreadcrumbToSentryAndRethrow
  );

  // Flow can't understand that 'logErrorBreadcrumbToSentryAndRethrow' will never return (it rethrows) and thus this
  // is of type 'ResponseDataSuccess<APIResponse>' if the code doesn't crash.
  const typeSafeResponse = ((response: any): ResponseDataSuccess<APIResponse>);

  if (typeSafeResponse.responseType === "SUCCESS") {
    allResponses.push(typeSafeResponse);
    // $FlowExpectedError
    ({ total_pages: totalPages } = typeSafeResponse.data.meta);
    if (totalPages > 1) {
      const allRequestURLs = range(2, totalPages + 1).map((page) => requestURLWithPageParam(requestURL, page));
      const remainingRequests = allRequestURLs.map((url) => fetchWithAuth(url, options));
      const remainingResponses = await timeoutPromise(TIMEOUT_MS, Promise.all(remainingRequests)).catch(
        logErrorBreadcrumbToSentryAndRethrow
      );
      // Flow can't understand that 'logErrorBreadcrumbToSentryAndRethrow' will never return (it rethrows) and thus
      // this is of type 'Array<ResponseDataSuccess<APIResponse>>' if the code doesn't crash.
      const typeSafeRemainingResponses = ((remainingResponses: any): Array<ResponseDataSuccess<APIResponse>>);
      allResponses = [...allResponses, ...typeSafeRemainingResponses];
    }
  } else {
    throw new InternalOnlyError("Fetching first page failed.");
  }

  const successResponses: Array<ResponseDataSuccess<APIResponse>> = [];
  allResponses.forEach((unknownResponse: any) => {
    if (unknownResponse.responseType === "SUCCESS") {
      successResponses.push(unknownResponse);
    } else if (unknownResponse.responseType === "ERROR") {
      throw new Error(unknownResponse.error);
    } else {
      throw new InternalOnlyError("We're in an unknown state. fetchWithAuth returned invalid data.");
    }
  });

  const successfullyParsedResponses = [];
  successResponses.forEach((successResponse) => {
    successfullyParsedResponses.push(successResponse);
  });

  return successfullyParsedResponses;
}
