/**
 * @flow
 */

import { requestSuggestOrgNode } from "../../shared/api/orgNodeAPI";
import { requestRegionEmployeesSearchResults } from "../../shared/api/regionEmployeeAPI";
import type { AutoSuggestDefinition, AutoSuggestDefinitionMap } from "../../shared/utils/autoSuggestUtils";
import type { SimpleRegionEmployee } from "../../shared/models/employee";
import type { SimpleOrgNode } from "../../shared/models/orgNode";

/* eslint-disable i18next/no-literal-string */
const EmployeeAutoSuggestDefinition: AutoSuggestDefinition<SimpleRegionEmployee> = {
  ariaLabelledBy: "auto-suggest employee by name or employee number",
  resultKeyFn: (result: SimpleRegionEmployee) => result.id,
  resultDisplayFn: (result: SimpleRegionEmployee) =>
    `${result.name} (${result.externalEmployeeNumber}) (${result.activeStatus})`,
  lookupFn: requestRegionEmployeesSearchResults,
  cacheKey: "cache-key-employees",
};
/* eslint-enable i18next/no-literal-string */

const OrgNodeReferencePathAutoSuggestDefinition: AutoSuggestDefinition<SimpleOrgNode> = {
  // eslint-disable-next-line i18next/no-literal-string
  ariaLabelledBy: "auto-suggest org node by reference path",
  resultKeyFn: (result: SimpleOrgNode) => result.referenceID,
  resultDisplayFn: (result: SimpleOrgNode) => `${result.referencePath}`,
  lookupFn: requestSuggestOrgNode,
  cacheKey: "cache-key-orgnode",
};

export const AUTO_SUGGEST_TYPES_MAP: AutoSuggestDefinitionMap = {
  employee: EmployeeAutoSuggestDefinition,
  org_node: OrgNodeReferencePathAutoSuggestDefinition,
};
