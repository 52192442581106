/**
 * @flow
 */

import { apiBase } from "../const/apiConstants";
import { parseRawRegionEmployee, parseRawSimpleRegionEmployeeArray } from "../models/employee";
import { fetchWithAuthAndParseResponse, ResponseError } from "../fetchWithAuth";
import type {
  RawRegionEmployee,
  RawSimpleRegionEmployee,
  RegionEmployee,
  SimpleRegionEmployee,
} from "../models/employee";
import type { RawUnion } from "../models/union";
import { InternalOnlyError } from "../utils/errors";
import { createIDMap } from "../utils/parsingUtils";

type RegionEmployeeAPIResponseData = {
  region_employee: RawRegionEmployee,
  unions: Array<RawUnion>,
};

const parseRegionEmployeeAPIResponseData = (data: RegionEmployeeAPIResponseData): RegionEmployee => {
  if (data.unions[0] == null) {
    throw new InternalOnlyError(
      `This request is for one employee, so there will always be exactly one related Union (this is a non-nullable 
      relationship). Given this info, we can safely index into the unions array assuming there's exactly 1 value. If
      there isn't, you're seeing this error, and things are broken.`
    );
  }
  return parseRawRegionEmployee(data.region_employee, { unions: createIDMap(data.unions) });
};

export const requestRegionEmployee = (id: number): Promise<RegionEmployee | ResponseError> =>
  fetchWithAuthAndParseResponse(
    `${window.location.origin}${apiBase}common/region-employees/${id}/?include[]=union.*`,
    parseRegionEmployeeAPIResponseData
  );

type RegionEmployeeSearchAPIResponseData = Array<RawSimpleRegionEmployee>;

export const parseRegionEmployeeSearchAPIResponseData = (
  data: RegionEmployeeSearchAPIResponseData
): Array<SimpleRegionEmployee> => {
  return parseRawSimpleRegionEmployeeArray(data);
};

export const requestRegionEmployeesSearchResults = (
  searchString: string
): Promise<Array<SimpleRegionEmployee> | ResponseError> => {
  return fetchWithAuthAndParseResponse(
    `${window.location.origin}${apiBase}common/region-employees/search/?value=${searchString}&per_page=8`,
    parseRegionEmployeeSearchAPIResponseData
  );
};
