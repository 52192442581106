/**
 * @flow
 */

export type RegionFacility = {|
  id: number,
  name: string,
  shortName: ?string,
|};

export type RawRegionFacility = {
  id: number,
  name: string,
  short_name: ?string,
};

export const parseRawRegionFacility = (rawRegionFacility: RawRegionFacility): RegionFacility => {
  const { id, name, ...rest } = rawRegionFacility;
  return {
    id,
    name,
    shortName: rest.short_name,
  };
};
