/**
 * @flow
 */

import { parseRawRegionDepartment } from "../../../../common/webpack/shared/models/department";
import { parseOptionalObjectFromSideloadMap } from "../../../../common/webpack/shared/utils/parsingUtils";

import type { RegionDepartment } from "../../../../common/webpack/shared/models/department";
import type { SideloadedMap } from "../../../../common/webpack/shared/utils/parsingUtils";
import { parseRawOccupation } from "../../../../sched_common/webpack/shared/models/occupation";
import type { Occupation } from "../../../../sched_common/webpack/shared/models/occupation";

export type RawVacationRequestShift = {
  id: number,
  vacation_request: number,
  duration_hours: number,
  shift_icon: string,
  shift_payroll_code: number,
  shift_class: string,
  shift_department: number,
  shift_date: string,
  shift_occupation: number,
  shift_start_timestamp: string,
  shift_end_timestamp: string,
  is_supernumerary: boolean,
  relief_necessary: ?boolean,
};

export type VacationRequestShift = {
  id: number,
  vacationRequestID: number,
  durationHours: number,
  payrollCodeID: number,
  shiftIcon: string,
  shiftClassification: string,
  shiftDepartmentID: number,
  shiftDepartment: ?RegionDepartment,
  shiftDate: string,
  shiftOccupationID: number,
  shiftOccupation: ?Occupation,
  shiftStartTimestamp: string,
  shiftEndTimestamp: string,
  isSupernumerary: boolean,
  reliefNecessary: ?boolean,
};

export const parseRawVacationRequestShift = (
  rawRequestShift: RawVacationRequestShift,
  sideloadedMap: SideloadedMap
): VacationRequestShift => {
  return {
    id: rawRequestShift.id,
    vacationRequestID: rawRequestShift.vacation_request,
    durationHours: rawRequestShift.duration_hours,
    payrollCodeID: rawRequestShift.shift_payroll_code,
    shiftIcon: rawRequestShift.shift_icon,
    shiftClassification: rawRequestShift.shift_class,
    shiftDepartmentID: rawRequestShift.shift_department,
    shiftDepartment: parseOptionalObjectFromSideloadMap(
      sideloadedMap,
      "region_departments",
      rawRequestShift.shift_department,
      parseRawRegionDepartment
    ),
    shiftDate: rawRequestShift.shift_date,
    shiftOccupationID: rawRequestShift.shift_occupation,
    shiftOccupation: parseOptionalObjectFromSideloadMap(
      sideloadedMap,
      "occupations",
      rawRequestShift.shift_occupation,
      parseRawOccupation
    ),
    shiftStartTimestamp: rawRequestShift.shift_start_timestamp,
    shiftEndTimestamp: rawRequestShift.shift_end_timestamp,
    isSupernumerary: rawRequestShift.is_supernumerary,
    reliefNecessary: rawRequestShift.relief_necessary,
  };
};

export const parseRawVacationRequestShiftArray = (
  rawRequestShifts: Array<RawVacationRequestShift>,
  sideloadedMap: SideloadedMap
): Array<VacationRequestShift> => rawRequestShifts.map((r) => parseRawVacationRequestShift(r, sideloadedMap));
