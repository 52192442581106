/**
 * @flow
 */

type ReferencePath = string;

export type RawSimpleOrgNode = {|
  reference_id: string,
  reference_path: ReferencePath,
|};

export type SimpleOrgNode = {|
  referenceID: string,
  referencePath: ReferencePath,
|};

export type RawOrgNode = {|
  reference_id: string,
  parent_reference_id: string,
  reference_path: ReferencePath,
  name: string,
  depth: number,
  is_leaf: boolean,
|};

export type OrgNode = {|
  referenceID: string,
  parentReferenceID: string,
  referencePath: ReferencePath,
  name: string,
  isLeaf: boolean,
  depth: number,
  children: ?Array<string>,
|};

const parseRawSimpleOrgNode = (rawOrgNode: RawSimpleOrgNode): SimpleOrgNode => {
  return {
    referenceID: rawOrgNode.reference_id,
    referencePath: rawOrgNode.reference_path,
  };
};

export const parseRawSimpleOrgNodeArray = (rawOrgNodes: Array<RawSimpleOrgNode>): Array<SimpleOrgNode> =>
  rawOrgNodes.map(parseRawSimpleOrgNode);

const parseRawOrgNode = ({
  reference_id: referenceID,
  reference_path: referencePath,
  parent_reference_id: parentReferenceID,
  is_leaf: isLeaf,
  depth,
  name,
}: RawOrgNode): OrgNode => {
  return {
    referenceID,
    referencePath,
    parentReferenceID,
    isLeaf,
    depth,
    name,
    // We don't get children back from the API. We do an API call for children of specified nodes,
    // then manually set that node's children using the result.
    children: null,
  };
};

export const parseRawOrgNodeArray = (rawOrgNodes: Array<RawOrgNode>): Array<OrgNode> =>
  rawOrgNodes.map(parseRawOrgNode);
