/**
 * @flow
 */
import type { AdvancedQuota } from "./vacationGroup";

export type RawVacationRoundDateRangeQuota = {
  id: number,
  vacation_round: number,
  start_date: string,
  end_date: string,
  single_daily_quota: number,
  advanced_quota?: AdvancedQuota,
};

export type VacationRoundDateRangeQuota = {|
  id: number,
  vacationRoundID: number,
  startDate: string,
  endDate: string,
  singleDailyQuota: number,
  advancedQuota?: AdvancedQuota,
|};

export const parseRawVacationRoundDateRangeQuota = ({
  id,
  vacation_round: vacationRoundID,
  start_date: startDate,
  end_date: endDate,
  single_daily_quota: singleDailyQuota,
  advanced_quota: advancedQuota,
}: RawVacationRoundDateRangeQuota): VacationRoundDateRangeQuota => ({
  id,
  vacationRoundID,
  startDate,
  endDate,
  singleDailyQuota,
  advancedQuota,
});

export const parseRawVacationRoundDateRangeQuotaArray = (
  rawVacationRoundDateRangeQuotaArray: Array<RawVacationRoundDateRangeQuota>
): Array<VacationRoundDateRangeQuota> => rawVacationRoundDateRangeQuotaArray.map(parseRawVacationRoundDateRangeQuota);
