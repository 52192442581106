/**
 * @flow
 */

import { parseRawRegionEmployee } from "../../../../common/webpack/shared/models/employee";
import { parseOptionalObjectFromSideloadMap } from "../../../../common/webpack/shared/utils/parsingUtils";
import { vacationRequestsFromRaw } from "./vacationRequest";
import { parseRawVacationRound } from "./vacationRound";
import { parseRawVacationRoundParticipantEligibilityRangeArray } from "./vacationRoundParticipantEligibilityRange";

import type { RegionEmployee } from "../../../../common/webpack/shared/models/employee";
import type { VacationRound } from "./vacationRound";
import type { RawVacationRequest, VacationRequest } from "./vacationRequest";
import type {
  RawVacationRoundParticipantEligibilityRange,
  VacationRoundParticipantEligibilityRange,
} from "./vacationRoundParticipantEligibilityRange";
import type { SideloadedMap } from "../../../../common/webpack/shared/utils/parsingUtils";

export const VacationRoundParticipantStates = {
  Created: ("created": "created"),
  Submitted: ("submitted": "submitted"),
  ManagerOverriden: ("manager_overriden": "manager_overriden"),
  ManagerDenied: ("manager_denied": "manager_denied"),
  ManagerApproved: ("approved": "approved"),
  SkippedNoRequests: ("skipped_no_requests": "skipped_no_requests"),
  Processing: ("processing": "processing"),
  AdminRemoved: ("admin_removed": "admin_removed"),
};
export const VacationRoundParticipantStateSets = {
  Skipped: [VacationRoundParticipantStates.SkippedNoRequests],
  Finalized: [
    VacationRoundParticipantStates.ManagerDenied,
    VacationRoundParticipantStates.ManagerApproved,
    VacationRoundParticipantStates.SkippedNoRequests,
    VacationRoundParticipantStates.AdminRemoved,
  ],
};
export type VacationRoundParticipantState = $Values<typeof VacationRoundParticipantStates>;

export type RawVacationRoundParticipant = {
  id: number,
  vacation_round: number,
  // This is a deferred relationship, so it might not exist in the raw data.
  vacation_requests?: Array<RawVacationRequest>,
  eligibility_ranges?: Array<RawVacationRoundParticipantEligibilityRange>,
  employee: number,
  employee_to_manager_comment: string,
  is_processed: boolean,
  seniority_sort: number,
  seniority_display: string,
  original_estimated_entitlement_hours: number,
  remaining_estimated_entitlement_hours: number,
  original_supplemental_vacation_hours: number,
  remaining_supplemental_vacation_hours: number,
  fsm_state: [VacationRoundParticipantState, string],
};

export type VacationRoundParticipant = {
  id: number,
  vacationRoundID: number,
  vacationRound: ?VacationRound,
  vacationRequests: ?Array<VacationRequest>,
  eligibilityRanges: ?Array<VacationRoundParticipantEligibilityRange>,
  employeeID: number,
  employee: ?RegionEmployee,
  employeeToManagerComment: string,
  isProcessed: boolean,
  senioritySort: number,
  seniorityDisplay: string,
  originalEstimatedEntitlementHours: number,
  remainingEstimatedEntitlementHours: number,
  originalSupplementalVacationHours: number,
  remainingSupplementalVacationHours: number,
  state: VacationRoundParticipantState,
  displayState: string,
};

export function parseRawVacationRoundParticipant(
  rawParticipant: RawVacationRoundParticipant,
  sideloadedMap: SideloadedMap
): VacationRoundParticipant {
  return {
    id: rawParticipant.id,
    employeeID: rawParticipant.employee,
    employee: parseOptionalObjectFromSideloadMap(
      sideloadedMap,
      "region_employees",
      rawParticipant.employee,
      parseRawRegionEmployee
    ),
    employeeToManagerComment: rawParticipant.employee_to_manager_comment,
    vacationRoundID: rawParticipant.vacation_round,
    vacationRound: parseOptionalObjectFromSideloadMap(
      sideloadedMap,
      "vacation_rounds",
      rawParticipant.vacation_round,
      (rawRound, sideloads) => parseRawVacationRound(rawRound, sideloads, true, false)
    ),
    vacationRequests: rawParticipant.vacation_requests
      ? vacationRequestsFromRaw(rawParticipant.vacation_requests, sideloadedMap)
      : null,
    eligibilityRanges: rawParticipant.eligibility_ranges
      ? parseRawVacationRoundParticipantEligibilityRangeArray(rawParticipant.eligibility_ranges, sideloadedMap)
      : null,
    state: rawParticipant.fsm_state[0],
    displayState: rawParticipant.fsm_state[1],
    isProcessed: rawParticipant.is_processed,
    originalEstimatedEntitlementHours: rawParticipant.original_estimated_entitlement_hours,
    remainingEstimatedEntitlementHours: rawParticipant.remaining_estimated_entitlement_hours,
    originalSupplementalVacationHours: rawParticipant.original_supplemental_vacation_hours,
    remainingSupplementalVacationHours: rawParticipant.remaining_supplemental_vacation_hours,
    senioritySort: rawParticipant.seniority_sort,
    seniorityDisplay: rawParticipant.seniority_display,
  };
}

export function parseRawVacationRoundParticipantArray(
  rawParticipants: Array<RawVacationRoundParticipant>,
  sideloadedMap: SideloadedMap
): Array<VacationRoundParticipant> {
  return rawParticipants.map((p) => parseRawVacationRoundParticipant(p, sideloadedMap));
}
