/**
 * @flow
 */

import {
  fetchWithNoExpectedResponseData,
  fetchWithAuthAndParseResponse,
  ResponseError,
  type ResponseNoDataSuccess,
} from "../../../../common/webpack/shared/fetchWithAuth";
import { apiBase } from "../../../../common/webpack/shared/const/apiConstants";
import { createIDMap } from "../../../../common/webpack/shared/utils/parsingUtils";
import {
  DefaultAdvancedQuotaSubtypes,
  parseRawSimpleVacationGroup,
  parseRawVacationGroup,
} from "../models/vacationGroup";

import type { RawOccupation } from "../../../../sched_common/webpack/shared/models/occupation";
import type { RawRegionDepartment } from "../../../../common/webpack/shared/models/department";
import type {
  RawSimpleVacationGroup,
  RawVacationGroup,
  SimpleVacationGroup,
  VacationGroup,
} from "../models/vacationGroup";
import type { RawVacationGroupEligibility } from "../models/vacationGroupEligibility";
import type { RawVacationProcess } from "../models/vacationProcess";
import type { RawVacationRound } from "../models/vacationRound";
import type { RawVacationRoundDateRangeQuota } from "../models/vacationRoundDateRangeQuota";

const fsmActionParams = Object.freeze({
  SET_QUOTA: "fsm-input=set_quota",
  SET_TO_SINGLE_QUOTA: "fsm-input=set_to_single_quota",
  SET_TO_ADVANCED_QUOTA: "fsm-input=set_to_advanced_quota",
});

// This is what the server wants.
type RawQuota = {
  start_date: string,
  end_date: string,
  single_daily_quota: number,
  advanced_quota: ?[number, number, number],
};

export const requestSetQuota = (
  vacationGroup: number,
  quotas: Array<RawQuota>
): Promise<ResponseNoDataSuccess | ResponseError> => {
  return fetchWithNoExpectedResponseData(
    `${window.location.origin}${apiBase}annual-vacation/vacation-groups/` +
      `${vacationGroup}/fsm/?${fsmActionParams.SET_QUOTA}`,
    {
      method: "post",
      body: JSON.stringify(quotas),
    }
  );
};

type VacationGroupDetailsAPIResponseData = {
  vacation_group: RawVacationGroup,
  vacation_processes?: Array<RawVacationProcess>,
  vacation_rounds?: Array<RawVacationRound>,
  vacation_groups?: Array<RawVacationGroup>,
  vacation_group_eligibilities?: Array<RawVacationGroupEligibility>,
  vacation_round_date_range_quota?: Array<RawVacationRoundDateRangeQuota>,
  region_departments?: Array<RawRegionDepartment>,
  occupations?: Array<RawOccupation>,
};

export const parseVacationGroupDetailsAPIResponseData = (data: VacationGroupDetailsAPIResponseData): VacationGroup => {
  const sideloadedMap = {
    vacation_processes: createIDMap(data.vacation_processes),
    vacation_rounds: createIDMap(data.vacation_rounds),
    vacation_group_eligibilities: createIDMap(data.vacation_group_eligibilities),
    quotas: createIDMap(data.vacation_round_date_range_quota),
    region_departments: createIDMap(data.region_departments),
    occupations: createIDMap(data.occupations),
  };
  return parseRawVacationGroup(data.vacation_group, sideloadedMap);
};

export function requestVacationGroup(vacationGroupID: number): Promise<VacationGroup | ResponseError> {
  const includes = [
    "vacation_process.*",
    "vacation_group_eligibilities.*",
    "vacation_group_eligibilities.department.*",
    "vacation_group_eligibilities.occupation.*",
    "vacation_rounds.*",
  ]
    .map((include) => `include[]=${include}`)
    .join("&");
  const excludes = [
    // To keep the payload sane, let's exclude participants explicitly here.
    "vacation_rounds.participants",
    "vacation_process.vacation_groups",
  ]
    .map((exclude) => `exclude[]=${exclude}`)
    .join("&");
  const fullBase = `${window.location.origin}${apiBase}`;
  return fetchWithAuthAndParseResponse(
    `${fullBase}annual-vacation/vacation-groups/${vacationGroupID}?${includes}&${excludes}`,
    parseVacationGroupDetailsAPIResponseData
  );
}

type VacationGroupSearchResultsAPIResponseData = {
  vacation_groups: Array<RawSimpleVacationGroup>,
};

function parseVacationGroupSearchResultsAPIResponseData(
  data: VacationGroupSearchResultsAPIResponseData
): Array<SimpleVacationGroup> {
  return data.vacation_groups.map(parseRawSimpleVacationGroup);
}

export function requestVacationGroupSearchResults(
  searchString: string,
  additionalFilters: Array<[string, string]>
): Promise<Array<SimpleVacationGroup> | ResponseError> {
  const includes = ["id", "name_formatted", "name_unformatted"].map((include) => `include[]=${include}`).join("&");
  // eslint-disable-next-line i18next/no-literal-string
  const filterTypeToValue = [["name_unformatted.icontains", searchString], ...additionalFilters];
  const filters = filterTypeToValue
    .map(([filterType, filterValue]) => `filter{${filterType}}=${filterValue}`)
    .join("&");

  const fullBase = `${window.location.origin}${apiBase}`;
  let requestUrl = `${fullBase}annual-vacation/vacation-groups/?exclude[]=*&${includes}&${filters}`;

  // The api expects the process to be included as a get param if one exists
  const vacationProcess = additionalFilters.find((filter) => filter[0] === "vacation_process");
  if (vacationProcess) {
    requestUrl = requestUrl.concat(`&vacation_process=${vacationProcess[1]}`);
  }

  return fetchWithAuthAndParseResponse(requestUrl, parseVacationGroupSearchResultsAPIResponseData);
}

export const createVacationGroupAndEligibilities = (
  vacationProcessId: number,
  customName: string,
  dailyQuota: number,
  advancedQuota: ?[number, number, number],
  approvers: Array<number>,
  eligibilities: Array<[number, number]>
): Promise<VacationGroup | ResponseError> => {
  const vacationGroup = JSON.stringify({
    approvers,
    eligibilities,
    vacation_process: vacationProcessId,
    custom_name: customName,
    default_daily_quota: dailyQuota,
    default_advanced_quota: advancedQuota,
    advanced_quota_subtypes: advancedQuota != null ? DefaultAdvancedQuotaSubtypes : undefined,
  });
  const fullBase = `${window.location.origin}${apiBase}`;
  return fetchWithAuthAndParseResponse(
    `${fullBase}annual-vacation/vacation-groups/build-and-create`,
    (data) => parseRawVacationGroup(data, {}),
    { method: "post", body: vacationGroup }
  );
};

export const requestSetToAdvancedQuota = (vacationGroupId: number): Promise<ResponseNoDataSuccess | ResponseError> => {
  return fetchWithNoExpectedResponseData(
    `${window.location.origin}${apiBase}annual-vacation/vacation-groups/${vacationGroupId}/fsm/?` +
      `${fsmActionParams.SET_TO_ADVANCED_QUOTA}`,
    { method: "post" }
  );
};

export const requestSetToSingleQuota = (vacationGroupId: number): Promise<ResponseNoDataSuccess | ResponseError> => {
  return fetchWithNoExpectedResponseData(
    `${window.location.origin}${apiBase}annual-vacation/vacation-groups/${vacationGroupId}/fsm/?` +
      `${fsmActionParams.SET_TO_SINGLE_QUOTA}`,
    { method: "post" }
  );
};
