/**
 * @flow
 */

import compact from "lodash/compact";

export type SideloadedMap = {
  [string]: ?Map<number, any>,
};

/* eslint-disable i18next/no-literal-string */
const NoSideloadedDataForKey: "NoSideloadedDataForKey" = "NoSideloadedDataForKey";
const ModelNotSideloaded: "ModelNotSideloaded" = "ModelNotSideloaded";
const InstanceNotSideloaded: "InstanceNotSideloaded" = "InstanceNotSideloaded";
/* eslint-enable i18next/no-literal-string */

type LookUpError = typeof ModelNotSideloaded | typeof InstanceNotSideloaded | typeof NoSideloadedDataForKey;
/**
 * Takes a set of objects and builds an id-based map. So the objects:
 *    [
 *      {id: 1, name: "one"},
 *      {id: 2, name: "two"}
 *    ]
 * will be mapped to the result:
 *    Map(
 *      1: {id: 1, name: "one"},
 *      2: {id: 2, name: "two"}
 *    )
 *
 * We use this to parse sideloaded data from dynamic rest. If we get an undefined set of data for one of our sideloads
 * from dynamic rest, we still want to return an empty map, not null. We do this because if we tried to sideload some
 * data, and DRF returns nothing, that means there was no data to sideload, so the sideload is empty, not undefined.
 *
 * @param objects
 * @returns {*}
 */
export function createIDMap<I, O: { id: any }>(objects?: Array<O>): Map<I, O> {
  if (objects) {
    return objects.reduce((acc, cur) => acc.set(cur.id, cur), new Map());
  }
  return new Map();
}

export function createFieldKeyedObject<I>(key: string, objects: Array<any>): Map<string, I> {
  return objects.reduce((acc, cur) => acc.set(cur[key], cur), new Map());
}

/**
 * @param sideloadedMap - the sideloaded map to look up data within.
 * @param mapKey - the key to the sideloaded map (which itself contains maps).
 * @param objectIDs - the objectIDs to use to do ID based lookups in the inner map for the map corresponding to mapKey.
 * @param parseFn - a function to parsed the looked up object.
 * @returns {*} - An array of parsed objects that were sideloaded.
 */
export function parseArrayFromSideloadMap<RawType, ParsedType>(
  sideloadedMap: SideloadedMap,
  mapKey: string,
  objectIDs: Array<number>,
  parseFn: (RawType, sideloadedMap: SideloadedMap) => ParsedType
): Array<ParsedType> | LookUpError {
  const sideloadedDataForKey = sideloadedMap[mapKey];
  if (sideloadedDataForKey === null) {
    return NoSideloadedDataForKey;
  }
  if (!sideloadedDataForKey) {
    return ModelNotSideloaded;
  }
  const rawObjectsFromMap: Array<RawType> = compact(objectIDs.map((objectID) => sideloadedDataForKey.get(objectID)));
  if (rawObjectsFromMap && rawObjectsFromMap.length !== objectIDs.length) {
    return InstanceNotSideloaded;
  }
  return rawObjectsFromMap.map((rawObject) => parseFn(rawObject, sideloadedMap));
}

export function parseOptionalArrayFromSideloadMap<RawType, ParsedType>(
  sideloadedMap: SideloadedMap,
  mapKey: string,
  objectIDs: Array<number>,
  parseFn: (RawType, sideloadedMap: SideloadedMap) => ParsedType
): ?Array<ParsedType> {
  const result = parseArrayFromSideloadMap(sideloadedMap, mapKey, objectIDs, parseFn);
  if (Array.isArray(result)) {
    return result;
  }
  return null;
}

/**
 * @param sideloadedMap - the sideloaded map to look up data within.
 * @param mapKey - the key to the sideloaded map (which itself contains maps).
 * @param objectID - the objectID to use to do an ID based lookup in the inner map for the map corresponding to mapKey.
 * @param parseFn - a function to parsed the looked up object.
 * @returns {*} - A parsed object that was sideloaded.
 */
export function parseObjectFromSideloadMap<RawType, ParsedType>(
  sideloadedMap: SideloadedMap,
  mapKey: string,
  objectID: number,
  parseFn: (RawType, sideloadedMap: SideloadedMap) => ParsedType
): ParsedType | LookUpError {
  // Let's leverage the array lookup method.
  const results = parseArrayFromSideloadMap(sideloadedMap, mapKey, [objectID], parseFn);
  if (Array.isArray(results)) {
    return results[0];
  }
  // If we don't have an array it's a LookUpError.
  return results;
}

export function parseOptionalObjectFromSideloadMap<RawType, ParsedType>(
  sideloadedMap: SideloadedMap,
  mapKey: string,
  objectID: number,
  parseFn: (RawType, sideloadedMap: SideloadedMap) => ParsedType
): ?ParsedType {
  const result = parseObjectFromSideloadMap(sideloadedMap, mapKey, objectID, parseFn);
  if (result === "ModelNotSideloaded" || result === "InstanceNotSideloaded" || result === "NoSideloadedDataForKey") {
    return null;
  }
  return result;
}
