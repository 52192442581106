/**
 * @flow
 */

import React from "react";
import ReactDOM from "react-dom";
import { AutoSuggestInput } from "../components/AutoSuggestInput";
import { ResponseError } from "../fetchWithAuth";
import { InternalOnlyError } from "./errors";

type AutoSuggestLookupFn<ResultType> = (
  searchString: string,
  additionalFilters: Array<[string, string]>
) => Promise<Array<ResultType> | ResponseError>;
type AutoSuggestAdditionalFiltersFn = () =>
  | { type: "error", message: string }
  | { type: "success", data: Array<[string, string]> };
export type AutoSuggestDefinition<ResultType> = {
  ariaLabelledBy: string,
  resultKeyFn: (result: ResultType) => string | number,
  resultDisplayFn: (result: ResultType) => string,
  lookupFn: AutoSuggestLookupFn<any>,
  additionalFiltersFn?: AutoSuggestAdditionalFiltersFn,
  cacheKey?: string,
};
export type AutoSuggestDefinitionMap = {
  [key: string]: AutoSuggestDefinition<any>,
};

const safeSearchRequestFn = (searchString, lookupFn, maybeAdditionalFiltersFn) => {
  return new Promise((resolve, reject) => {
    const maybeAdditionalFilters = maybeAdditionalFiltersFn != null ? maybeAdditionalFiltersFn() : null;
    if (maybeAdditionalFilters == null) {
      // In this case we can run the lookup function with no additional filters, as no function was defined.
      resolve(lookupFn(searchString, []));
      return;
    }
    // We had an additional filters function that ran, but we still need to check if it returned an error.
    const unsafeAdditionalFilters = maybeAdditionalFilters;
    if (unsafeAdditionalFilters.type === "error") {
      reject(
        new ResponseError({
          code: 400,
          message: unsafeAdditionalFilters.message,
        })
      );
      return;
    }
    resolve(lookupFn(searchString, unsafeAdditionalFilters.data));
  });
};

const AUTO_SUGGEST_INPUT_CLASS = ".auto-suggest-input";
/**
 * What we're looking for is a piece of HTML structured like this:
     <div class="control">
       <input name="field-name" class="input auto-suggest-input">
     </div>
 * This is how you'd normally render a bulma input in a form. This might also be surrounded in a div with class
 * 'field', but this logic doesn't care about that. We find inputs with class 'auto-suggest-input' that are
 * sourrounded by a div with class 'control' and we replace the contents of that div. Notably we preserve the 'name'
 * attribute of the input tag, so we can leverage the name attribute set by Django forms.
 */
export const initializeAutoSuggestFieldsForMap = (autoSuggestMap: AutoSuggestDefinitionMap) => {
  const allAutoSuggestInputContainers = document.querySelectorAll(AUTO_SUGGEST_INPUT_CLASS);
  allAutoSuggestInputContainers.forEach((autoSuggestInput) => {
    const { autoSuggestType } = autoSuggestInput.dataset;
    if (autoSuggestMap[autoSuggestType] == null) {
      const allowed = Object.keys(autoSuggestMap).join(", ");
      throw new InternalOnlyError(
        `Auto-suggest field is misconfigured. Invalid type: ${autoSuggestType}. Allowed types: ${allowed}.`
      );
    }
    const { ariaLabelledBy, resultKeyFn, resultDisplayFn, lookupFn, additionalFiltersFn, cacheKey } =
      autoSuggestMap[autoSuggestType];

    const autoSuggestField = autoSuggestInput.closest("div");
    if (autoSuggestField == null) {
      throw new InternalOnlyError(
        "Auto-suggest field is misconfigured. A node with class 'field' could not be found containing our 'input'."
      );
    }
    if (!autoSuggestField.classList.contains("control")) {
      throw new InternalOnlyError(
        `Auto-suggest field is misconfigured. An 'input' node was found, but it isn't contained by a div with class
        'control' on it. This structure is required.`
      );
    }

    const autoSuggestInputNameAttribute = autoSuggestInput.getAttribute("name") || "";
    const required = autoSuggestInput.hasAttribute("required");

    ReactDOM.render(
      <AutoSuggestInput
        cacheKey={cacheKey}
        onSelectBehaviour="fill"
        fieldInputName={autoSuggestInputNameAttribute}
        ariaLabelledBy={ariaLabelledBy}
        searchRequestFn={(searchTerm) => safeSearchRequestFn(searchTerm, lookupFn, additionalFiltersFn)}
        resultKeyFn={resultKeyFn}
        resultDisplayFn={resultDisplayFn}
        required={required}
      />,
      autoSuggestField
    );
  });
};
