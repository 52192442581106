/**
 * @flow
 */

import {
  parseOptionalArrayFromSideloadMap,
  parseOptionalObjectFromSideloadMap,
} from "../../../../common/webpack/shared/utils/parsingUtils";
import { parseRawVacationProcess } from "./vacationProcess";
import { parseRawVacationRound } from "./vacationRound";
import { parseRawVacationGroupEligibility } from "./vacationGroupEligibility";

import type { VacationProcess } from "./vacationProcess";
import type { VacationRound } from "./vacationRound";
import type { VacationGroupEligibility } from "./vacationGroupEligibility";
import type { SideloadedMap } from "../../../../common/webpack/shared/utils/parsingUtils";

// Advanced quota is currently working off the assumption that all quota subtypes are based on shift classes. In the
// future, advanced quota subtypes may be configured based on some other criteria, so the current subtype defaults
// are not hardcoded into object creation. Instead, pull the defaults from here until this is revisited.
export const DefaultAdvancedQuotaSubtypes: ["Day", "Evening", "Night"] = ["Day", "Evening", "Night"];
export type AdvancedQuotaSubtypes = Array<string>;
export type AdvancedQuota = Array<number>;

export type RawVacationGroup = {
  id: number,
  current_round_name: ?string,
  vacation_rounds?: Array<number>,
  vacation_group_eligibilities: Array<number>,
  is_active: boolean,
  vacation_process: number,
  default_daily_quota: number,
  default_weekly_quota: number,
  scheduler_processed_timestamp: ?string,
  created_timestamp: string,
  fsm_state: [string, string],
  scheduler_processed_by_user: ?number,
  created_by_user: number,
  time_zone: string,
  name_unformatted: string,
  name_formatted: string,
  advanced_quota_subtypes: Array<string>,
};

export type VacationGroup = {|
  id: ?number,
  currentRoundName: ?string,
  isActive: boolean,
  vacationProcessID: number,
  vacationProcess: ?VacationProcess,
  defaultDailyQuota: number,
  defaultWeeklyQuota: number,
  schedulerProcessedTimestamp: ?string,
  createdTimestamp: string,
  state: string,
  displayState: string,
  schedulerProcessedByUserID: ?number,
  createdByUserID: number,
  timeZone: string,
  vacationRoundIDs: ?Array<number>,
  vacationRounds: ?Array<VacationRound>,
  vacationGroupEligibilityIDs: Array<number>,
  vacationGroupEligibilities: ?Array<VacationGroupEligibility>,
  nameUnformatted: string,
  nameFormatted: string, // We'll always need to mark this safe when used in react.
  advancedQuotaSubtypes: AdvancedQuotaSubtypes,
|};

export type RawSimpleVacationGroup = {
  id: number,
  name_formatted: string,
  name_unformatted: string,
};

export type SimpleVacationGroup = {
  id: number,
  nameFormatted: string,
  nameUnformatted: string,
};

// DOM parser is a safe way to decode &amp; in a way that isn't vulnerable to XSS attacks.
const parser: DOMParser = new DOMParser();
export const parseRawSimpleVacationGroup = (
  // eslint-disable-next-line camelcase
  { id, name_formatted, name_unformatted }: RawSimpleVacationGroup
): SimpleVacationGroup => {
  const parsedNameFormatted = parser.parseFromString(name_formatted, "text/html").documentElement;
  const parsedNameUnformatted = parser.parseFromString(name_unformatted, "text/html").documentElement;
  return {
    id,
    nameFormatted: parsedNameFormatted != null ? parsedNameFormatted.textContent : "",
    nameUnformatted: parsedNameUnformatted != null ? parsedNameUnformatted.textContent : "",
  };
};

export const parseRawVacationGroup = (
  rawVacationGroup: RawVacationGroup,
  sideloadedMap: SideloadedMap
): VacationGroup => {
  const {
    id,
    current_round_name: currentRoundName,
    is_active: isActive,
    vacation_rounds: vacationRoundIDs,
    vacation_group_eligibilities: vacationGroupEligibilityIDs,
    vacation_process: vacationProcessID,
    default_daily_quota: defaultDailyQuota,
    default_weekly_quota: defaultWeeklyQuota,
    scheduler_processed_timestamp: schedulerProcessedTimestamp,
    scheduler_processed_by_user: schedulerProcessedByUserID,
    created_by_user: createdByUserID,
    time_zone: timeZone,
    created_timestamp: createdTimestamp,
    name_formatted: nameFormatted,
    name_unformatted: nameUnformatted,
    advanced_quota_subtypes: advancedQuotaSubtypes,
  } = rawVacationGroup;
  const [state, displayState] = rawVacationGroup.fsm_state;
  return {
    id,
    currentRoundName,
    state,
    displayState,
    isActive,
    vacationRounds: parseOptionalArrayFromSideloadMap(
      sideloadedMap,
      "vacation_rounds",
      vacationRoundIDs ?? [],
      // When a group sets its rounds, we need to inform the rounds not to set its group or we'll infinitely loop.
      (rawVacationRound, innerSideloadedMap) =>
        parseRawVacationRound(rawVacationRound, innerSideloadedMap, false, false)
    ),
    vacationRoundIDs,
    vacationGroupEligibilities: parseOptionalArrayFromSideloadMap(
      sideloadedMap,
      "vacation_group_eligibilities",
      vacationGroupEligibilityIDs,
      parseRawVacationGroupEligibility
    ),
    vacationGroupEligibilityIDs,
    vacationProcessID,
    // If group is parsing process, we don't want process to parse groups or we'll infinitely loop.
    vacationProcess: parseOptionalObjectFromSideloadMap(
      sideloadedMap,
      "vacation_processes",
      vacationProcessID,
      (rawVacationProcess, innerSideloadedMap) => parseRawVacationProcess(rawVacationProcess, innerSideloadedMap, false)
    ),
    defaultDailyQuota,
    defaultWeeklyQuota,
    schedulerProcessedTimestamp,
    schedulerProcessedByUserID,
    createdByUserID,
    createdTimestamp,
    timeZone,
    nameFormatted,
    nameUnformatted,
    advancedQuotaSubtypes,
  };
};

export const parseRawVacationGroupArray = (
  rawVacationGroupArray: Array<RawVacationGroup>,
  sideloadedMap: SideloadedMap
): Array<VacationGroup> => {
  return rawVacationGroupArray.map((rawVacationGroup) => parseRawVacationGroup(rawVacationGroup, sideloadedMap));
};
