/**
 @flow
 */

export type RawOccupation = {
  id: number,
  long_description: string,
  desc: string,
  job_code: number,
  occupation_class: number,
  union: number,
  display_str: string,
};

export type Occupation = {
  id: number,
  longDescription: string,
  description: string,
  jobCode: number,
  occupationClassID: number,
  unionID: number,
  displayStr: string,
};

const parser: DOMParser = new DOMParser();
export const parseRawOccupation = (rawOccupation: RawOccupation): Occupation => {
  // eslint-disable-next-line camelcase
  const { id, long_description, ...rest } = rawOccupation;
  const parsedLongDescription = parser.parseFromString(long_description, "text/html").documentElement;
  const parsedDescription = parser.parseFromString(rest.desc, "text/html").documentElement;
  const parsedDisplayStr = parser.parseFromString(rest.display_str, "text/html").documentElement;
  return {
    id,
    longDescription: parsedLongDescription != null ? parsedLongDescription.textContent : "",
    description: parsedDescription != null ? parsedDescription.textContent : "",
    jobCode: rest.job_code,
    occupationClassID: rest.occupation_class,
    unionID: rest.union,
    displayStr: parsedDisplayStr != null ? parsedDisplayStr.textContent : "",
  };
};

export const parseRawOccupationArray = (rawOccupationArray: Array<RawOccupation>): Array<Occupation> =>
  rawOccupationArray.map(parseRawOccupation);
