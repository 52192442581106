/**
 * @flow
 */

import { requestVacationGroupSearchResults } from "../../shared/api/vacationGroupAPI";
import type {
  AutoSuggestDefinition,
  AutoSuggestDefinitionMap,
} from "../../../../common/webpack/shared/utils/autoSuggestUtils";
import type { SimpleVacationGroup } from "../../shared/models/vacationGroup";

const VacationGroupAutoSuggestDefinition: AutoSuggestDefinition<SimpleVacationGroup> = {
  ariaLabelledBy: "auto-suggest vacation group by name",
  resultKeyFn: (result: SimpleVacationGroup) => result.id,
  resultDisplayFn: (result: SimpleVacationGroup) => result.nameUnformatted,
  lookupFn: requestVacationGroupSearchResults,
  additionalFiltersFn: () => {
    const selectElements = document.getElementsByTagName("select");
    const vacationProcessSelect = selectElements.namedItem("vacation_process");
    if (vacationProcessSelect == null) {
      return { type: "error", message: "Vacation process must be set." };
    }
    const vacationProcessID = vacationProcessSelect.options[vacationProcessSelect.selectedIndex].value;
    return {
      type: "success",
      data: [
        // eslint-disable-next-line i18next/no-literal-string
        ["vacation_process", vacationProcessID],
      ],
    };
  },
};

export const AUTO_SUGGEST_TYPES_MAP: AutoSuggestDefinitionMap = {
  vacationGroupNameDependingOnVacationProcess: VacationGroupAutoSuggestDefinition,
};
