/**
 * @flow
 */

import { useMemo, useEffect } from "react";
import throttle from "lodash/throttle";

/**
 * https://github.com/streamich/react-use/issues/372#issuecomment-500235222
 */
export const useThrottleFn = (
  fn: (...args: Array<any>) => any,
  ms: number = 1000
): ((...args: Array<any>) => any) & any => {
  // Should "Cancelable" instead of & any, but our types don't cover this.
  const throttledFn = useMemo(() => {
    return throttle(fn, ms);
  }, [fn, ms]);
  // This is a must because fn can call setState.
  useEffect(() => {
    return () => {
      // $FlowExpectedError - throttle has a 'cancel' function but our flow types don't seem to cover it.
      throttledFn.cancel();
    };
  }, [throttledFn]);
  return throttledFn;
};
