/**
 * @flow
 */

import { parseRawRegionFacility } from "./facility";
import type { RawRegionFacility, RegionFacility } from "./facility";
import { parseRawSchedulingTeam } from "./schedulingTeam";
import type { RawSchedulingTeam, SchedulingTeam } from "./schedulingTeam";

export type RawRegionDepartment = {
  id: number,
  identifier: string,
  name: string,
  short_name: ?string,
  // The DynamicRegionDepartment serializer always returns facility if it isn't excluded, so it will always either be
  // deferred or a concrete object, not an ID.
  facility?: RawRegionFacility,
  manager_position_numbers?: Array<number>,
  managers_manager_position_numbers?: Array<number>,
  time_zone: string,
  display_str: string,
  scheduling_team?: RawSchedulingTeam,
};

export type RegionDepartment = {
  id: number,
  identifier: string,
  name: string,
  shortName: ?string,
  facility?: RegionFacility,
  managerPositionNumbers: ?Array<number>,
  managersManagerPositionNumbers: ?Array<number>,
  timeZone: string,
  displayStr: string,
  schedulingTeam?: SchedulingTeam,
};

const parser: DOMParser = new DOMParser();
export const parseRawRegionDepartment = (rawRegionDepartment: RawRegionDepartment): RegionDepartment => {
  const {
    id,
    identifier,
    name,
    facility,
    manager_position_numbers: managerPositionNumbers,
    managers_manager_position_numbers: managersManagerPositionNumbers,
    scheduling_team: schedulingTeam,
    ...rest
  } = rawRegionDepartment;
  const parsedNamed = parser.parseFromString(name, "text/html").documentElement;
  const parsedShortNamed =
    rest.short_name != null ? parser.parseFromString(rest.short_name, "text/html").documentElement : null;
  return {
    id,
    identifier,
    name: parsedNamed != null ? parsedNamed.textContent : "",
    shortName: parsedShortNamed != null ? parsedShortNamed.textContent : "",
    timeZone: rest.time_zone,
    displayStr: rest.display_str,
    managerPositionNumbers: managerPositionNumbers !== undefined ? managerPositionNumbers : null,
    managersManagerPositionNumbers:
      managersManagerPositionNumbers !== undefined ? managersManagerPositionNumbers : null,
    ...((facility != null ? { facility: parseRawRegionFacility(facility) } : { ...null }): {|
      facility?: RegionFacility,
    |}),
    ...((schedulingTeam != null ? { schedulingTeam: parseRawSchedulingTeam(schedulingTeam) } : { ...null }): {|
      schedulingTeam?: SchedulingTeam,
    |}),
  };
};

export const parseRawRegionDepartmentArray = (
  rawRegionDepartmentArray: Array<RawRegionDepartment>
): Array<RegionDepartment> => rawRegionDepartmentArray.map(parseRawRegionDepartment);
