/**
 * @flow
 */

import { useCallback, useEffect, useState } from "react";
import { ResponseError } from "../fetchWithAuth";
import { useThrottleFn } from "./useThrottleFn";

const cache = {};
function fetchResults<T>(
  searchTerm: string,
  searchFn: (string) => Promise<Array<T> | ResponseError>,
  cacheKey: ?string
): Promise<Array<T> | ResponseError> {
  if (cacheKey && cache[cacheKey]?.[searchTerm]) {
    return Promise.resolve(cache[cacheKey][searchTerm]);
  }
  return searchFn(searchTerm).then((result) => {
    if (cacheKey && cache[cacheKey]) {
      cache[cacheKey][searchTerm] = result;
    } else if (cacheKey) {
      cache[cacheKey] = { [searchTerm]: result };
    }
    return result;
  });
}

export function useSearch<T>(
  searchTerm: string,
  searchFn: (string) => Promise<Array<T> | ResponseError>,
  cacheKey: ?string,
  throttleMs: number = 1000
): [Array<T>, (Array<T>) => void, boolean] {
  const [searchResults, setSearchResults] = useState<Array<T>>([]);
  const [isPerformingSearch, setIsPerformingSearch] = useState(false);
  const performSearchFn = useThrottleFn(
    useCallback(
      (term: string) => {
        if (term.trim() !== "") {
          let isFresh = true;
          setIsPerformingSearch(true);
          fetchResults(term, searchFn, cacheKey).then((results) => {
            if (isFresh && !(results instanceof ResponseError)) {
              setSearchResults(results);
            }
            setIsPerformingSearch(false);
          });
          return () => {
            isFresh = false;
          };
        }
        setSearchResults([]);
        return undefined;
      },
      [searchFn, cacheKey]
    ),
    throttleMs
  );

  useEffect(() => {
    performSearchFn(searchTerm);
    setSearchResults([]);
  }, [performSearchFn, searchTerm]);

  return [searchResults, setSearchResults, isPerformingSearch];
}
